import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen8 } from '../../../components/images/support/certifications/Screen8.generated';
import { Screen9 } from '../../../components/images/support/certifications/Screen9.generated';
import { Screen10 } from '../../../components/images/support/certifications/Screen10.generated';
import { Screen11 } from '../../../components/images/support/certifications/Screen11.generated';
import { Screen12 } from '../../../components/images/support/certifications/Screen12.generated';
import { Screen13 } from '../../../components/images/support/certifications/Screen13.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "assigning-certifications-to-positions-"
    }}>{`Assigning Certifications To Positions 🗂`}</h1>
    <p>{`In this example we will assign a Certification as a requirement to a specific Position.`}</p>
    <p>{`Make sure you have created Certifications before you begin this step. You can learn how to do that `}<a parentName="p" {...{
        "href": "/support/certifications/adding-certifications"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "lets-assign-a-certification"
    }}>{`Let's assign a Certification`}</h3>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Positions menu.`}</li>
    </ol>

    <Screen8 mdxType="Screen8" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Select the position you would like to assign a Certification to.`}</li>
    </ol>

    <Screen9 mdxType="Screen9" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click "Assign" in the "Required Certifications" section.`}</li>
    </ol>

    <Screen10 mdxType="Screen10" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select the required Certification from the menu in this case it is a "White Card" that we set up previously. Then click "Submit".`}</li>
    </ol>

    <Screen11 mdxType="Screen11" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Navigate to the Certifications menu.`}</li>
    </ol>

    <Screen12 mdxType="Screen12" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`We can now see all the employees required to have the relevant certification. In our example Elon Musk is required to hold a White Card.`}</li>
    </ol>

    <Screen13 mdxType="Screen13" />
    <p>{`Great Job you have assigned your Certification 🎉`}</p>
    <p>{`After an employee uploads their Certifications they will need to be approved.`}</p>

    <SupportFooter linkTo="/support/certifications/approving-certifications" linkText="Learn how to approve Certifications" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      